<template>
  <div
    ref="drop"
    v-click-outside="handleClickOutside"
    class="dropdown"
    :class="classes"
    :tabindex="props.tabindex"
    @blur="onBlur"
  >
    <span v-if="props.label" class="label">
      {{ props.label }}
      <span v-if="props.isRequired" class="required">*</span>
    </span>

    <div class="selected" @click="open">
      <atomic-image v-if="valueObject?.mask" class="mask" :src="valueObject?.mask" />
      <span v-if="valueObject">{{ valueObject?.value }}</span>
      <span v-else-if="props.placeholder" class="placeholder">
        {{ props.placeholder }}
      </span>
      <span class="selected__icon">
        <atomic-icon id="arrow_expand-close" />
      </span>
    </div>

    <!-- bottom sheet for mobile -->
    <client-only v-if="isMobile">
      <layout-bottom-sheet ref="dropdownBottomSheet">
        <template #header>
          <div class="title">
            {{ props.label }}
          </div>
          <div class="header">
            <form-input-search
              v-if="isShowSearch"
              ref="inputSearch"
              v-model:value="searchValue"
              :placeholder="searchPlaceholder"
              @focus="isOpen = true"
            />
          </div>
        </template>

        <div
          v-for="option in filteredItems"
          :key="option.name"
          class="dropdown__item"
          :class="[
            { dropdown__item_selected: option.code === valueObject?.code },
            { 'dropdown__item_disabled-option': option.disabled },
          ]"
          @click="select(option)"
        >
          <atomic-image v-if="option.mask" class="dropdown__img" :src="option.mask" />
          <span class="dropdown__text"
            >{{ option.value }}{{ option.name && isShowName ? ` (${option.name})` : '' }}</span
          >
        </div>

        <template #footer>
          <button-base type="gray" @click="toggleDropdownBottomSheet('close')">
            {{ getContent(layoutData, defaultLocaleLayoutData, 'buttons.close') }}
          </button-base>
        </template>
      </layout-bottom-sheet>
    </client-only>

    <div v-if="options.length && !isMobile" ref="dropItems" class="items">
      <div class="dropdown-search">
        <form-input-search
          v-if="isShowSearch"
          ref="inputSearch"
          v-model:value="searchValue"
          :placeholder="searchPlaceholder"
          @focus="isOpen = true"
        />
      </div>

      <div
        v-for="option in filteredItems"
        :key="option.name"
        class="item"
        :class="[{ 'is-selected': option.code === valueObject?.code }, { 'disabled-option': option.disabled }]"
        @click="select(option)"
      >
        <atomic-image v-if="option.mask" class="mask" :src="option.mask" />
        <slot :option="option">
          <span>{{ option.value }}{{ option.name && isShowName ? ` (${option.name})` : '' }}</span>
        </slot>
      </div>
    </div>

    <atomic-hint v-if="props.hint" v-bind="props.hint" />
    <input type="hidden" :name="props.name" :value="props.value" />
  </div>
</template>

<script setup lang="ts">
  import type { IFieldHint, IOption } from '@skeleton/types';
  const { layoutData, defaultLocaleLayoutData } = storeToRefs(useGlobalStore());
  const deviceStore = useDeviceStore();
  const { isMobile } = storeToRefs(deviceStore);

  const { getContent } = useProjectMethods();

  const dropdownBottomSheet = ref();
  const toggleDropdownBottomSheet = (methodName: 'open' | 'close') => {
    if (!isMobile.value) return;
    dropdownBottomSheet.value?.[methodName]();
  };

  interface IProps {
    options: IOption[];
    value: object | string | number;
    isShowSearch?: boolean;
    searchPlaceholder?: string;
    isRequired?: boolean;
    label?: string;
    placeholder?: string;
    tabindex?: number;
    name?: string;
    size?: 'xs' | 'sm' | 'md' | 'lg' | '';
    isDisabled?: boolean;
    hint?: IFieldHint;
    isFitContent?: boolean;
    isShowName?: boolean;
  }

  const props = withDefaults(defineProps<IProps>(), {
    options: () => [],
    isRequired: false,
    label: '',
    placeholder: 'Dropdown placeholder',
    tabindex: 0,
    size: '',
    isDisabled: false,
    isFitContent: false,
    isShowName: false,
    isShowSearch: true,
    searchPlaceholder: 'Search',
  });

  const valueObject = computed(() => {
    return props.options.find((option: any) => option.code === props.value) || null;
  });

  const searchValue = ref<string>('');
  const inputSearch = ref(null);

  const emit = defineEmits(['input', 'focus', 'update:value', 'selectOption']);

  const isOpen = ref<boolean>(false);
  const drop = ref<HTMLElement>();
  const dropItems = ref<HTMLElement>();
  const selectedItem = ref<Maybe<IOption>>(null);

  const classes = computed(() => [
    props.size ? `size-${props.size}` : null,
    { 'is-open': isOpen.value },
    { 'has-error': props.hint?.variant === 'error' },
    { 'is-disabled': props.isDisabled },
    { 'is-fit-content': props.isFitContent },
  ]);

  const select = (option: IOption) => {
    emit('update:value', option.code);
    emit('input', option.code);
    emit('selectOption', option);

    selectedItem.value = option;

    isOpen.value = false;

    toggleDropdownBottomSheet('close');
  };

  const handleClickOutside = () => {
    if (isOpen.value) {
      isOpen.value = false;
    }
  };

  const open = async (): Promise<void> => {
    if (props.isDisabled) return;

    toggleDropdownBottomSheet('open');

    searchValue.value = '';
    isOpen.value = !isOpen.value;

    if (isOpen.value) {
      setTimeout(() => inputSearch.value?.focus(), 100);
    }

    emit('focus');
  };

  const onBlur = (): void => {
    isOpen.value = false;
  };

  const filteredItems = computed(() => {
    const searchQuery = searchValue.value.toLowerCase().trim();

    if (!searchQuery) {
      return props.options;
    }

    return props.options.filter((item: IOption) => {
      return (
        item.value?.toString().toLowerCase().includes(searchQuery) ||
        item.name?.toString().toLowerCase().includes(searchQuery)
      );
    });
  });

  onMounted(() => {
    if (props.isFitContent && drop.value && dropItems.value) {
      const width = dropItems.value?.offsetWidth;
      drop.value.style.width = `${width}px`;
      dropItems.value.style.width = `${width}px`;
    }
  });
</script>

<style src="~/assets/styles/components/form/input/dropdown.scss" lang="scss" />
